import LoadingPage from 'auth/LoadingPage';
import GenericError from 'common/GenericError';
import PageHeader from 'common/PageHeader';
import React from 'react';
import { useIntl } from 'react-intl';
import { useTenantConfig } from 'tenant';
import assertUnreachable from 'util/assertUnreachable';
import ApiTokensSection from './ApiTokensSection';
import GenerateTokenModalProvider from './ApiTokensSection/CreateApiTokenModal/GenerateTokenModalProvider';
import { DeleteApiTokenModalProvider } from './ApiTokensSection/ApiTokensTable/ConfirmDeleteApiTokenModalProvider';
import LanguageSettings from './LanguageSettings';
import useIsGatewayAdmin from 'auth/useIsGatewayAdmin';
import AdminSettings from './AdminSettings';

const SettingsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const data = useTenantConfig();
  const isAdmin = useIsGatewayAdmin();

  switch (data.status) {
    case 'Error':
      return <GenericError />;
    case 'Loading':
      return <LoadingPage />;
    case 'Success':
      return (
        <>
          <PageHeader
            title={formatMessage({
              defaultMessage: 'Settings',
              description: 'Settings page title',
            })}
          />
          <LanguageSettings />
          {isAdmin && (
            <>
              <AdminSettings />
              <GenerateTokenModalProvider>
                <DeleteApiTokenModalProvider>
                  <ApiTokensSection />
                </DeleteApiTokenModalProvider>
              </GenerateTokenModalProvider>
            </>
          )}
        </>
      );
    default:
      return assertUnreachable(data);
  }
};

export default SettingsPage;
