import { Section, Spinner } from '@snapchat/snap-design-system';
import React from 'react';
import { useIntl } from 'react-intl';
import EnablePixelJSHostingToggle from './EnablePixelHostingToggle';
import IntegrationInput from './IntegrationInput';
import useAdminConfig from 'tenant/useAdminConfig';
import assertUnreachable from 'util/assertUnreachable';
import { adminSettingsSectionClassName } from './styles';

const AdminSettings: React.FC = () => {
  const { formatMessage } = useIntl();
  const data = useAdminConfig();

  switch (data.status) {
    case 'Loading':
      return (
        <Section
          title={formatMessage({
            defaultMessage: 'Admin Settings',
            description: 'Admin Settings page title',
          })}
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner size='x-large' />
          </div>
        </Section>
      );
    case 'Error':
      return null;
    case 'Success':
      return (
        <Section
          title={formatMessage({
            defaultMessage: 'Admin Settings',
            description: 'Admin Settings page title',
          })}
          bodyClassName={adminSettingsSectionClassName}
        >
          <EnablePixelJSHostingToggle config={data.admin} />
          <IntegrationInput adminConfig={data.admin} />
        </Section>
      );
    default:
      return assertUnreachable(data);
  }
};

export default AdminSettings;
