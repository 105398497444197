import { Toast } from '@snapchat/snap-design-system';
import { useMutation } from '@tanstack/react-query';
import { updateTenantConfig } from 'api/api';
import { ADMIN_TENANT_ID } from 'common/constants';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { toJson } from 'translators/AdminConfigTranslator';
import { AdminConfig } from 'types';

const useUpdateIntegration = () => {
  const { formatMessage } = useIntl();
  const { error } = useContext(Toast.Context);

  const mutation = useMutation({
    mutationKey: ['updateTenant', ADMIN_TENANT_ID],
    mutationFn: async (config: AdminConfig) => await updateTenantConfig(toJson(config)),
    onError: () =>
      error({
        title: formatMessage({
          defaultMessage: 'Failed to update integration',
          description: 'Error message when updating Pixel Hosting status fails',
        }),
      }),
  });

  return mutation;
};

export default useUpdateIntegration;
