import { AdminConfigJson } from 'api/types';
import { fromJson as tenantFromJson, toJson as tenantToJson } from './TenantConfigTranslator';
import { AdminConfig } from 'types';

export const fromJson = (adminConfig: AdminConfigJson): AdminConfig => {
  return {
    ...tenantFromJson(adminConfig),
    dnsSetupType: adminConfig.dns_setup_type,
    cloudflareToken: adminConfig.cloudflare_token,
    pixelHostingEnabled: adminConfig.pixel_hosting_enabled,
    integrationField: adminConfig.integration_field,
  };
};

export const toJson = (adminConfig: AdminConfig): AdminConfigJson => {
  return {
    ...tenantToJson(adminConfig),
    dns_setup_type: adminConfig.dnsSetupType,
    cloudflare_token: adminConfig.cloudflareToken,
    pixel_hosting_enabled: adminConfig.pixelHostingEnabled,
    integration_field: adminConfig.integrationField,
  };
};
