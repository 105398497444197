import { Colors, Input, Spinner } from '@snapchat/snap-design-system';
import React, { useState } from 'react';
import useUpdateIntegration from './useUpdateIntegration';
import { useIntl } from 'react-intl';
import { AdminConfig } from 'types';
import { DnsSetupType } from 'api/types';
import { CheckIcon } from '@snapchat/snap-design-system-icons';

interface Props {
  adminConfig: AdminConfig;
}

const IntegrationInput: React.FC<Props> = ({ adminConfig }) => {
  const { formatMessage } = useIntl();
  const mutation = useUpdateIntegration();
  const [integration, setIntegration] = useState(adminConfig.integrationField);

  if (adminConfig.dnsSetupType === DnsSetupType.Aws) {
    return null;
  }

  return (
    <Input
      label={formatMessage({
        defaultMessage: 'Integration Field',
        description: 'Integration input label',
      })}
      tooltip={formatMessage(
        {
          defaultMessage:
            'This value will be sent in the {integrationField} field on all CAPI events that are sent through this gateway',
          description: 'Integration input tooltip',
        },
        {
          integrationField: 'integration',
        },
      )}
      placeholder={formatMessage({
        defaultMessage: 'Integration Field',
        description: 'Integration input placeholder',
      })}
      value={integration}
      onChange={(val) => setIntegration(val)}
      onBlur={() => {
        if (integration !== adminConfig.integrationField) {
          mutation.mutate({ ...adminConfig, integrationField: integration });
        }
      }}
      suffix={
        mutation.status === 'pending' ? (
          <Spinner />
        ) : mutation.status === 'success' ? (
          <CheckIcon fill={Colors.Green.V150} />
        ) : null
      }
    />
  );
};

export default IntegrationInput;
